import React from "react"
import { graphql } from "gatsby"
import PlaygroundContent from "../components/playgroundContent"
import { Layout, Seo } from "../components"
import "../styles/playground.scss"

const PlaygroundPage = ({ data }) => {
  return (
    <>
      <Layout>
        <Seo title="Statistics - Playground" />
        <PlaygroundContent allData={data} />
      </Layout>
    </>
  )
}

export const query = graphql`
  query PlaygroundPage {
    allData2021CsvSheet1 {
      nodes {
        id
        Lequel_des___nonc__s_suivants_vous_d__crit__
        Quel_est_votre___ge__en_ann__es___
        A_quel___ge_avez_vous___crit_votre_premi__re_ligne_de_code_ou_de_programme____par_exemple__page_Web__Hello_World__projet_Scratch_
        Laquelle_des_options_suivantes_vous_d__crit_le_mieux_aujourd_hui___
        Quel___tait_votre_domaine_d___tudes_principal_
        Lequel_des___nonc__s_suivants_d__crit_le_mieux_le_plus_haut_niveau_d___ducation_formelle_que_vous_avez_atteint___
        Laquelle_des_propositions_suivantes_correspond_le_mieux____votre_niveau_d_emploi_actuel__
        Lequel_des___nonc__s_suivants_d__crit_le_mieux_votre_statut_d_emploi_actuel___
        Lequel_des___nonc__s_suivants_d__crit_le_mieux_votre_statut_actuel_de_recherche_d_emploi___
        En_g__n__ral__qu_est_ce_qui_vous_pousse____chercher_un_nouvel_emploi__
        En_recherche_d_emploi__comment_faites_vous_pour_en_savoir_plus_sur_une_entreprise__
        Codez_vous_comme_passe_temps____
        Y_compris_toute___ducation__depuis_combien_d_ann__es_codez_vous_au_total___
        Sans_compter_l___ducation__combien_d_ann__es_avez_vous_cod___professionnellement__dans_le_cadre_de_votre_travail____
        Pensez_vous_que_votre_entreprise_a_un_bon_processus_d_int__gration__
        Quel_niveau_d_influence_avez_vous_personnellement_sur_l_acquisition_de_nouvelles_technologies_dans_votre_organisation___
        _xxquellexfrxxquencextravaillezxvousxdesxheuresxsupplxxmentairesxouxauxdelxxxdexlxattentexformellexdexvotrextravailxxx
        Quelle_est_votre_r__mun__ration_totale_actuelle__salaire__primes_et_avantages__avant_imp__ts_et_d__ductions___en_USD____Veuillez_entrer_un_nombre_entier_dans_la_case_ci_dessous__sans_aucune_ponctuation__Si_vous___tes_pay______l_heure__veuillez_estimer_un_salaire_hebdomadaire__mensuel_ou_annuel___quivalent__Si_vous_pr__f__rez_ne_pas_r__pondre__veuillez_laisser_la_case_vide_
        Cette_r__mun__ration_est_elle_hebdomadaire__mensuelle_ou_annuelle___
        Quelles_devises_utilisez_vous_au_quotidien___Si_votre_r__ponse_est_compliqu__e__veuillez_choisir_celle_avec_laquelle_vous___tes_le_plus____l_aise_pour_estimer_
        _xtesxvousxsatisfaitxdexvotrexemploixactuelxxxxxSixvousxavezxplusieursxemploisxxrxxpondezxpourxceluixsurxlequelxvousxpassezxlexplusxdxheuresxx
        Imaginez_que_vous_d__cidez_entre_deux_offres_d_emploi_avec_la_m__me_r__mun__ration__les_m__mes_avantages_et_le_m__me_emplacement_
        Environ_combien_de_personnes_sont_employ__es_par_l_entreprise_ou_l_organisation_pour_laquelle_vous_travaillez_actuellement___
        Votre_entreprise_a_t_elle_une_personne_DevOps_d__di__e___
        Quelle_est_l_importance_de_la_pratique_de_DevOps_pour_faire___voluer_le_d__veloppement_logiciel___
        Dans_quelle_mesure_une___ducation_formelle__telle_qu_un_dipl__me_universitaire_en_informatique__est_elle_importante_pour_votre_carri__re___
        Dans_quelle_province_habitez_vous_
        Dans_quelle_ville__
        Sur_quel_OUTILS_DE_COLLABORATION_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Asana_
        Sur_quel_OUTILS_DE_COLLABORATION_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Confluence_
        Sur_quel_OUTILS_DE_COLLABORATION_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Facebook_Workplace_
        Sur_quel_OUTILS_DE_COLLABORATION_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____GitHub_
        Sur_quel_OUTILS_DE_COLLABORATION_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____GitLab_
        Sur_quel_OUTILS_DE_COLLABORATION_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Google_Suite__Docs__Meet__etc__
        Sur_quel_OUTILS_DE_COLLABORATION_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Jira_
        Sur_quel_OUTILS_DE_COLLABORATION_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Microsoft_Azure_
        Sur_quel_OUTILS_DE_COLLABORATION_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Microsoft_Teams_
        Sur_quel_OUTILS_DE_COLLABORATION_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Slack_
        Sur_quel_OUTILS_DE_COLLABORATION_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Stack_Overflow_for_Teams_
        Sur_quel_OUTILS_DE_COLLABORATION_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Trello_
        Sur_quel_PLATEFORME_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_laquelle_souhaitez_vous_travailler_l_ann__e_prochaine_____AWS_
        Sur_quel_PLATEFORME_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_laquelle_souhaitez_vous_travailler_l_ann__e_prochaine_____Android_
        Sur_quel_PLATEFORME_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_laquelle_souhaitez_vous_travailler_l_ann__e_prochaine_____Arduino_
        Sur_quel_PLATEFORME_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_laquelle_souhaitez_vous_travailler_l_ann__e_prochaine_____Docker_
        Sur_quel_PLATEFORME_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_laquelle_souhaitez_vous_travailler_l_ann__e_prochaine_____Google_Cloud_Platform_
        Sur_quel_PLATEFORME_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_laquelle_souhaitez_vous_travailler_l_ann__e_prochaine_____Heroku_
        Sur_quel_PLATEFORME_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_laquelle_souhaitez_vous_travailler_l_ann__e_prochaine_____IBM_Cloud_or_Watson_
        Sur_quel_PLATEFORME_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_laquelle_souhaitez_vous_travailler_l_ann__e_prochaine_____Kubernetes_
        Sur_quel_PLATEFORME_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_laquelle_souhaitez_vous_travailler_l_ann__e_prochaine_____Linux_
        Sur_quel_PLATEFORME_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_laquelle_souhaitez_vous_travailler_l_ann__e_prochaine_____MacOS_
        Sur_quel_PLATEFORME_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_laquelle_souhaitez_vous_travailler_l_ann__e_prochaine_____Microsoft_Azure_
        Sur_quel_PLATEFORME_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_laquelle_souhaitez_vous_travailler_l_ann__e_prochaine_____Raspberry_Pi_
        Sur_quel_PLATEFORME_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_laquelle_souhaitez_vous_travailler_l_ann__e_prochaine_____Slack_Apps_and_Integrations_
        Sur_quel_PLATEFORME_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_laquelle_souhaitez_vous_travailler_l_ann__e_prochaine_____Windows_
        Sur_quel_PLATEFORME_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_laquelle_souhaitez_vous_travailler_l_ann__e_prochaine_____WordPress_
        Sur_quel_PLATEFORME_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_laquelle_souhaitez_vous_travailler_l_ann__e_prochaine_____iOS_
        Dans_quels_ENVIRONNEMENT_DE_BASE_DE_DONN__ES_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_dans_lequel_souhaitez_vous_travailler_l_ann__e_prochaine______Cassandra_
        Dans_quels_ENVIRONNEMENT_DE_BASE_DE_DONN__ES_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_dans_lequel_souhaitez_vous_travailler_l_ann__e_prochaine______Couchbase_
        Dans_quels_ENVIRONNEMENT_DE_BASE_DE_DONN__ES_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_dans_lequel_souhaitez_vous_travailler_l_ann__e_prochaine______DynamoDB_
        Dans_quels_ENVIRONNEMENT_DE_BASE_DE_DONN__ES_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_dans_lequel_souhaitez_vous_travailler_l_ann__e_prochaine______Elasticsearch_
        Dans_quels_ENVIRONNEMENT_DE_BASE_DE_DONN__ES_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_dans_lequel_souhaitez_vous_travailler_l_ann__e_prochaine______Firebase_
        Dans_quels_ENVIRONNEMENT_DE_BASE_DE_DONN__ES_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_dans_lequel_souhaitez_vous_travailler_l_ann__e_prochaine______IBM_DB2_
        Dans_quels_ENVIRONNEMENT_DE_BASE_DE_DONN__ES_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_dans_lequel_souhaitez_vous_travailler_l_ann__e_prochaine______MariaDB_
        Dans_quels_ENVIRONNEMENT_DE_BASE_DE_DONN__ES_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_dans_lequel_souhaitez_vous_travailler_l_ann__e_prochaine______Microsoft_SQL_Server_
        Dans_quels_ENVIRONNEMENT_DE_BASE_DE_DONN__ES_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_dans_lequel_souhaitez_vous_travailler_l_ann__e_prochaine______MongoDB_
        Dans_quels_ENVIRONNEMENT_DE_BASE_DE_DONN__ES_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_dans_lequel_souhaitez_vous_travailler_l_ann__e_prochaine______MySQL_
        Dans_quels_ENVIRONNEMENT_DE_BASE_DE_DONN__ES_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_dans_lequel_souhaitez_vous_travailler_l_ann__e_prochaine______Oracle_
        Dans_quels_ENVIRONNEMENT_DE_BASE_DE_DONN__ES_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_dans_lequel_souhaitez_vous_travailler_l_ann__e_prochaine______PostgreSQL_Redis_SQLite_
        Sur_quel_AUTRES_FRAMEWORK__LIBRAIRIES_et_OUTILS_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Ansible_
        Sur_quel_AUTRES_FRAMEWORK__LIBRAIRIES_et_OUTILS_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Apache_Spark_
        Sur_quel_AUTRES_FRAMEWORK__LIBRAIRIES_et_OUTILS_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Chef_
        Sur_quel_AUTRES_FRAMEWORK__LIBRAIRIES_et_OUTILS_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Cordova_
        Sur_quel_AUTRES_FRAMEWORK__LIBRAIRIES_et_OUTILS_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Flutter_
        Sur_quel_AUTRES_FRAMEWORK__LIBRAIRIES_et_OUTILS_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Hadoop_
        Sur_quel_AUTRES_FRAMEWORK__LIBRAIRIES_et_OUTILS_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Keras_
        Sur_quel_AUTRES_FRAMEWORK__LIBRAIRIES_et_OUTILS_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Node_js_
        Sur_quel_AUTRES_FRAMEWORK__LIBRAIRIES_et_OUTILS_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Pandas_
        Sur_quel_AUTRES_FRAMEWORK__LIBRAIRIES_et_OUTILS_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Puppet_
        Sur_quel_AUTRES_FRAMEWORK__LIBRAIRIES_et_OUTILS_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____React_Native_
        Sur_quel_AUTRES_FRAMEWORK__LIBRAIRIES_et_OUTILS_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____TensorFlow_
        Sur_quel_AUTRES_FRAMEWORK__LIBRAIRIES_et_OUTILS_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Terraform_
        Sur_quel_AUTRES_FRAMEWORK__LIBRAIRIES_et_OUTILS_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Torch_PyTorch_
        Sur_quel_AUTRES_FRAMEWORK__LIBRAIRIES_et_OUTILS_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Unity_3D_
        Sur_quel_AUTRES_FRAMEWORK__LIBRAIRIES_et_OUTILS_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Unreal_Engine_
        Sur_quel_AUTRES_FRAMEWORK__LIBRAIRIES_et_OUTILS_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Xamarin_
        Sur_quel_AUTRES_FRAMEWORK__LIBRAIRIES_et_OUTILS_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine______NET_
        Sur_quel_AUTRES_FRAMEWORK__LIBRAIRIES_et_OUTILS_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine______NET_Core_
        Sur_quel_FRAMEWORK_WEB_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____ASP_NET_
        Sur_quel_FRAMEWORK_WEB_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____ASP_NET_Core_
        Sur_quel_FRAMEWORK_WEB_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Angular_
        Sur_quel_FRAMEWORK_WEB_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Angular_js_
        Sur_quel_FRAMEWORK_WEB_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Django_
        Sur_quel_FRAMEWORK_WEB_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Drupal_
        Sur_quel_FRAMEWORK_WEB_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Express_
        Sur_quel_FRAMEWORK_WEB_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Flask_
        Sur_quel_FRAMEWORK_WEB_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Gatsby_
        Sur_quel_FRAMEWORK_WEB_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Laravel_
        Sur_quel_FRAMEWORK_WEB_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____React_js_
        Sur_quel_FRAMEWORK_WEB_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Ruby_on_Rails_
        Sur_quel_FRAMEWORK_WEB_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Spring_
        Sur_quel_FRAMEWORK_WEB_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Symfony_
        Sur_quel_FRAMEWORK_WEB_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____Vue_js_
        Sur_quel_FRAMEWORK_WEB_avez_vous_effectu___un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lequel_souhaitez_vous_travailler_l_ann__e_prochaine_____jQuery_
        Quels_LANGAGES_DE_PROGRAMMATION__de_SCRIPT_et_de_BALISAGE_avez_vous_fait_un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lesquels_souhaitez_vous_travailler_l_ann__e_prochaine___Assembly_
        Quels_LANGAGES_DE_PROGRAMMATION__de_SCRIPT_et_de_BALISAGE_avez_vous_fait_un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lesquels_souhaitez_vous_travailler_l_ann__e_prochaine___Bash_Shell_PowerShell_
        Quels_LANGAGES_DE_PROGRAMMATION__de_SCRIPT_et_de_BALISAGE_avez_vous_fait_un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lesquels_souhaitez_vous_travailler_l_ann__e_prochaine___C_
        Quels_LANGAGES_DE_PROGRAMMATION__de_SCRIPT_et_de_BALISAGE_avez_vous_fait_un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lesquels_souhaitez_vous_travailler_l_ann__e_prochaine___C__
        Quels_LANGAGES_DE_PROGRAMMATION__de_SCRIPT_et_de_BALISAGE_avez_vous_fait_un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lesquels_souhaitez_vous_travailler_l_ann__e_prochaine___C___
        Quels_LANGAGES_DE_PROGRAMMATION__de_SCRIPT_et_de_BALISAGE_avez_vous_fait_un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lesquels_souhaitez_vous_travailler_l_ann__e_prochaine___Dart_
        Quels_LANGAGES_DE_PROGRAMMATION__de_SCRIPT_et_de_BALISAGE_avez_vous_fait_un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lesquels_souhaitez_vous_travailler_l_ann__e_prochaine___Go_
        Quels_LANGAGES_DE_PROGRAMMATION__de_SCRIPT_et_de_BALISAGE_avez_vous_fait_un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lesquels_souhaitez_vous_travailler_l_ann__e_prochaine___HTML_CSS_
        Quels_LANGAGES_DE_PROGRAMMATION__de_SCRIPT_et_de_BALISAGE_avez_vous_fait_un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lesquels_souhaitez_vous_travailler_l_ann__e_prochaine___Haskell_
        Quels_LANGAGES_DE_PROGRAMMATION__de_SCRIPT_et_de_BALISAGE_avez_vous_fait_un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lesquels_souhaitez_vous_travailler_l_ann__e_prochaine___JavaScript_
        Quels_LANGAGES_DE_PROGRAMMATION__de_SCRIPT_et_de_BALISAGE_avez_vous_fait_un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lesquels_souhaitez_vous_travailler_l_ann__e_prochaine___Java_
        Quels_LANGAGES_DE_PROGRAMMATION__de_SCRIPT_et_de_BALISAGE_avez_vous_fait_un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lesquels_souhaitez_vous_travailler_l_ann__e_prochaine___Kotlin_
        Quels_LANGAGES_DE_PROGRAMMATION__de_SCRIPT_et_de_BALISAGE_avez_vous_fait_un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lesquels_souhaitez_vous_travailler_l_ann__e_prochaine___Objective_C_
        Quels_LANGAGES_DE_PROGRAMMATION__de_SCRIPT_et_de_BALISAGE_avez_vous_fait_un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lesquels_souhaitez_vous_travailler_l_ann__e_prochaine___PHP_
        Quels_LANGAGES_DE_PROGRAMMATION__de_SCRIPT_et_de_BALISAGE_avez_vous_fait_un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lesquels_souhaitez_vous_travailler_l_ann__e_prochaine___Perl_
        Quels_LANGAGES_DE_PROGRAMMATION__de_SCRIPT_et_de_BALISAGE_avez_vous_fait_un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lesquels_souhaitez_vous_travailler_l_ann__e_prochaine___Python_
        Quels_LANGAGES_DE_PROGRAMMATION__de_SCRIPT_et_de_BALISAGE_avez_vous_fait_un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lesquels_souhaitez_vous_travailler_l_ann__e_prochaine___R_
        Quels_LANGAGES_DE_PROGRAMMATION__de_SCRIPT_et_de_BALISAGE_avez_vous_fait_un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lesquels_souhaitez_vous_travailler_l_ann__e_prochaine___Ruby_
        Quels_LANGAGES_DE_PROGRAMMATION__de_SCRIPT_et_de_BALISAGE_avez_vous_fait_un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lesquels_souhaitez_vous_travailler_l_ann__e_prochaine___Rust_
        Quels_LANGAGES_DE_PROGRAMMATION__de_SCRIPT_et_de_BALISAGE_avez_vous_fait_un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lesquels_souhaitez_vous_travailler_l_ann__e_prochaine___SQL_
        Quels_LANGAGES_DE_PROGRAMMATION__de_SCRIPT_et_de_BALISAGE_avez_vous_fait_un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lesquels_souhaitez_vous_travailler_l_ann__e_prochaine___Scala_
        Quels_LANGAGES_DE_PROGRAMMATION__de_SCRIPT_et_de_BALISAGE_avez_vous_fait_un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lesquels_souhaitez_vous_travailler_l_ann__e_prochaine___Swift_
        Quels_LANGAGES_DE_PROGRAMMATION__de_SCRIPT_et_de_BALISAGE_avez_vous_fait_un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lesquels_souhaitez_vous_travailler_l_ann__e_prochaine___TypeScript_
        Quels_LANGAGES_DE_PROGRAMMATION__de_SCRIPT_et_de_BALISAGE_avez_vous_fait_un_travail_de_d__veloppement_approfondi_au_cours_de_l_ann__e___coul__e__et_sur_lesquels_souhaitez_vous_travailler_l_ann__e_prochaine___VBA_
        Quel_est_le_syst__me_d_exploitation_principal_sur_lequel_vous_travaillez__
        Que_faites_vous_lorsque_vous___tes_bloqu___sur_un_probl__me__
        Vous_recherchez_une_solution_de_codage_en_ligne_et_le_premier_lien_de_r__sultat_est_violet_car_vous_l_avez_d__j___visit____Comment_vous_sentez_vous_
        _xxquellexfrxxquencexapprenezxvousxunexnouvellexlanguexouxunxnouveauxframeworkxxx
        Lors_de_l_achat_d_un_nouvel_outil_ou_logiciel__comment_d__couvrez_vous_et_recherchez_vous_les_solutions_disponibles____
      }
    }
  }
`

export default PlaygroundPage
